<template>
  <div>
    <EditItemModal :nombrejaula="objJaula.descripcion" :datos="datosSetPoint" :open="modalstate"
      @updateNuevoDato="updateNuevoDato" @close="closeModal"></EditItemModal>


    <div class="text-xs col-span-2 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4  text-left">

      <span v-if="result_mediciones_haydatos"><span class="green custom-shadow"></span></span>

      <span v-if="this.idEstado === 9"><span class="red custom-shadow"></span></span>
      <span v-if="this.idEstado === 1"><span class="green custom-shadow"></span></span>
      <span v-if="this.idEstado === 2"><span class="blue custom-shadow"></span></span>
      <span v-if="this.idEstado === 3"><span class="yellow custom-shadow"></span></span>
      <span v-if="this.idEstado === 4"><span class="orange custom-shadow"></span></span>
      <span v-if="result_mediciones_haydatos == false"> <!-- o this.idEstado === 5-->
        <div class=" ml-2 rounded-full mb-1   flex items-center justify-center h-5 w-5 bg-gray-400">&nbsp;</div>
      </span>
      <span class="text-lg  font-bold ml-2 "> {{ objJaula.descripcion }} </span>

    </div>
    <div class="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-2 pl-1 pr-1">


      <div v-if="Object.keys(reqO2).length > 0"
        class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">

      </div>
      <div v-if="Object.keys(maxMgl).length > 0"
        class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">

      </div>
      <div v-if="Object.keys(maxORP).length > 0"
        class="text-xs text-purple-800 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">


      </div>
    </div>
    <div class="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4  ml-1 mr-1 pb-0 ">

      <div class="text-xs border-solid border-0 border-indigo-300 p-2 text-center pt-7">
        Temp &deg;C
      </div>
      <div v-if="Object.keys(reqO2).length > 0"
        class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">
        {{ this.reqO2.descripcion }} {{ reqO2_ume }}
      </div>
      <div v-if="Object.keys(maxMgl).length > 0"
        class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">
        {{ this.maxMgl.descripcion }} {{ maxMgl_ume }}
      </div>
      <div v-if="Object.keys(maxORP).length > 0"
        class="text-xs text-purple-800 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">

        {{ this.maxORP.descripcion }} {{ maxORP_ume }}
      </div>
    </div>


    <div class="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4  ml-1 mr-1 pb-0  ">

      <div
        class="text-xs   border-l-2 border-t-2 border-b-2 border-r-0  border-solid  border-indigo-300 p-2 text-center">
        {{ this.ulttemp }}
      </div>

      <div v-if="Object.keys(reqO2).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-2 border-indigo-300 p-2 text-center">
        {{ this.ultmaxmgl }}
      </div>
      <div v-if="Object.keys(maxMgl).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-2 border-indigo-300 p-2 text-center">
        {{ this.ultmgl }}
      </div>
      <div v-if="Object.keys(maxORP).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-2 border-indigo-300 p-2 text-center bg-gray-200">
        {{ this.ultorp }}
      </div>
    </div>
    <div class="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4  ml-1 mr-1 mb-4 ">

      <div class="text-xs border-solid border-0 border-indigo-300 p-2 text-center pt-7">
        Set Points
      </div>
      <div v-if="Object.keys(reqO2).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-0   bg-blue-600 border-indigo-300 p-2 text-center">
        <!-- SETPOINT SATURADO // requerido -->

        <input @click_desabilitado="openmodal('reqO2', reqO2_esDefault)" type="text" v-model="reqO2.value" :class="['text-center w-full text-xs p-2 h-8 shadow-sm border-solid border-2 border-blue-600',
          reqO2_esDefault ? 'bg-orange-100' : 'bg-white']" />
      </div>
      <div v-if="Object.keys(maxMgl).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-0   bg-blue-600 border-indigo-300 p-2 text-center">
        <!-- SETPOINT OXIGENO DISUELTO // requerido -->

        <input @click_desabilitado="openmodal('maxMgl', maxMgl_esDefault)" type="text" v-model="maxMgl.value" :class="['text-center w-full text-xs p-2 h-8 shadow-sm border-solid border-2 border-blue-600 ',
          maxMgl_esDefault ? 'bg-orange-100' : 'bg-white']" />
      </div>
      <div v-if="Object.keys(maxORP).length > 0"
        class="text-xs border-solid border-2 border-indigo-300 p-2 text-center  bg-purple-800">
        <!-- SET POINT ORP-->
        <!--  @click="openmodal('maxORP',maxORP_esDefault)"  -->
        <input type="text" v-model="maxORP.value" :class="['text-center text-xs w-full p-2 h-8 shadow-sm border-solid border-purple-800 border-2 ',
          maxORP_esDefault ? ' bg-gray-200' : 'bg-white']" />
      </div>
    </div>

    <div class="chart-container">

      <SpinnerLoading v-show="result_mediciones == false"></SpinnerLoading>

      <div class="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4 pl-2 pr-2 mr-0 pb-0 mt-2 mb-2 ">
        <div class=" text-center">

          <div class="flex justify-between">

            <div class="w-1/2 text-left">
              <div class=" mt-1   h-1 bg-[#01FFFF]   shadow-lg  ml-auto"></div>
            </div>
            <div class="w-1/2 text-right">
              <div class="mt-1  h-1 bg-[#0075c4]  shadow-lg  ml-auto"></div>
            </div>
          </div>

        </div>
        <div class=" text-center pl-1 w-100">
          
          O2 Disuelto mg/l  
          <span class="text-[#00e0e0]">con</span> / <span class="text-[#0075c4]">sin inyección</span>
        
        </div>
        <div class="text-center  ">

          
           
              <div class=" mt-1   h-1 bg-[#FF6E00] rounded-lg shadow-lg  ml-3"></div>
          
         
            
            
        
        </div>
        <div class=" text-center  w-15">
          Temperatura <br>°C
 
        </div>
      </div>
      <div class="w-full flex justify-center">

        <AlertDummy v-show="glosaDummyO2Disuelto != ''" :glosa="glosaDummyO2Disuelto"></AlertDummy>
        <AlertDummy v-show="glosaDummyO2Saturado != ''" :glosa="glosaDummyO2Saturado"></AlertDummy>
        <AlertDummy v-show="glosaDummyTemperatura != ''" :glosa="glosaDummyTemperatura"></AlertDummy>
        <AlertDummy v-show="glosaDummyOzono != ''" :glosa="glosaDummyOzono"></AlertDummy>

      </div>



      <highcharts :options="chartOptions"></highcharts>


    </div>
    <div class="chart-container">
      <div id="divfec" class="w-full flex justify-between">
        <div class="f1 pl-11 text-center">
          <!--    {{ firstdate }}<br>{{ firsthour }}-->
          {{ fechaInicialParche }}
          <br>
          {{ horaInicialParche }}
        </div>
        <div class="flex-grow text-center">
          <!--  {{ middledate }}<br>{{ middlehour }} -->
          {{ fechaMitadParche }}
          <br>
          {{ horaMitadParche }}
        </div>
        <div class="f2  text-center ml-1 pr-12">
          <!--     {{ lastdate }}<br>{{ lasthour }}-->

          {{ fechaFinalParche }}
          <br>
          {{ horaFinalParche }}

        </div>
      </div>
    </div>


    <textarea id="adjustable-textarea" class="w-full  p-2 border border-gray-300 rounded   text-xs
    focus:outline-none focus:ring-2 focus:ring-blue-500 text hidden" v-model="this.fs2"></textarea>
    <!-- hidden -->
    <!-- {{ this.chartOptions.series[0].zones  }} -->
  </div>

</template>
<script>

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import SpinnerLoading from "../SpinnerLoading";
import EditItemModal from './EditItemModal.vue'
import AlertDummy from "../AlertDummy.vue";
import axios from "axios";



stockInit(Highcharts);
//const colors = Highcharts.getOptions().colors;
Highcharts.setOptions({
  chart: {

  },

});

export default {
  props: ["objJaula", "injecta_fecha", "injecta_hora"],
  emits: ["ampliaGrafico", "muestraFoto", "fecha_hasta"],

  components: {
    highcharts: Chart,
    SpinnerLoading,
    EditItemModal,
    AlertDummy
  },
  data: function () {
    return {
      glosaDummyO2Saturado: '',
      glosaDummyO2Disuelto: '',
      glosaDummyTemperatura: '',
      glosaDummyOzono: '',
      fs: '',
      fs2: '',
      modalstate: false,
      idEstado: 5,
      setpoint1: 6.2,
      setpoint2: 300,
      idJaula: 0,
      ultfecha: '',
      ultmgl: 0,
      ultmaxmgl: 0,
      ultorp: 0,
      ulttemp: 0,
      temperatura: 0,
      saturacion: 0,
      idSensorAFiltrar0: 0,
      idSensorAFiltrar1: 0,
      granularity: '', // DAY, HOUR

      id1: 0,
      id2: 0,
      sensores: [],
      sensor: [{ 'id': '', 'nombre': '' }, { 'id': '', 'nombre': '' }],

      fInicial: '',
      fFinal: '',

      reqO2: {},
      maxORP: {},
      maxMgl: {},
      reqO2_tipo: '',
      maxORP_tipo: '',
      maxMgl_tipo: '',
      reqO2_ume: '',
      maxORP_ume: '',
      maxMgl_ume: '',
      reqO2_esDefault: '',
      maxORP_esDefault: '',
      maxMgl_esDefault: '',
      datosSetPoint: {},
      descripcionSetPoint: '',
      ume1: '',
      ume2: '',
      tipo1: '',
      tipo2: '',
      result_mediciones: false,
      result_mediciones_haydatos: false,
      firstdate: "",
      firsthour: "",
      lastdate: "",
      lasthour: "",
      middledate: "",
      middlehour: "",
      fechaInicialParche: "",
      horaInicialParche: "",
      fechaFinalParche: "",
      horaFinalParche: "",
      fechaMitadParche: "",
      horaMitadParche: "",
      valorMaxY0: 0,
      valorMinY0: 0,
      valorMaxY1: 0,
      valorMinY1: 0,
      minY: 0,
      maxY: 5,
      minY1: 0,
      maxY1: 15,
      chartOptions: {
        chart: {
          type: 'line',

        },
        title: {
          text: ''
        },
        colors: ['#0099ff', '#ff6e00'], // Colores de las series
        xAxis: {
          categories: [],
          labels: {
            enabled: true, // Deshabilitar etiquetas del eje X
            lineColor: 'gray'
          },
        },
        yAxis: [
          {

            title: {
              text: 'miligramos / litro',
              style: {
                color: '#0037ff',
              },
            },
            min: this.minY,
            max: this.maxY,
            labels: {
              style: {
                color: '#0037ff',
              },

            },
          },
          {
            opposite: true,
            title: {
              text: '°C',
              style: {
                color: '#ff6e00',
              },
            },
            min: this.minY1,
            max: this.maxY1,
            labels: {
              style: {
                color: '#ff6e00',
              },

            },
          },
        ],
        plotOptions: {
          line: {
            dataLabels: {
              enabled: false,
            },
            enableMouseTracking: true
          }
        },
        legend: {
          enabled: false // Ocultar la leyenda
        },
        series: [
          {
            name: '', // Nombre de la primera serie
            data: [0, 0, 0, 0], // Datos para la primera serie
            yAxis: 0, // Indica que esta serie usa el primer eje Y (el izquierdo),
            zones: [],
            zoneAxis: 'x',
            marker: {
              enabled: false,
            }

          },
          {

            name: '', // Nombre de la segunda serie
            data: [], // Datos para la segunda serie
            yAxis: 1, // Indica que esta serie usa el primer eje Y (el izquierdo)
            marker: {
              enabled: false,
            },
          },
        ],
        tooltip: {

        }
      }



    };
  },
  methods: {
    getRnd(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(2)
    },
    getRndUno(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(1)
    },
    propagajaula() {
      this.$emit("muestraFoto", this.idJaula, this.label);
    },
    polldata() {
      this.polling = setInterval(() => {
        this.getdataendpoint();
      }, 5000); //
    },

    closeModal() {
      this.modalstate = false
    },
    updateNuevoDato(datos) {
      console.log('updateNuevoDato', datos)
      if (datos.parametro == 'reqO2') {
        this.reqO2.value = datos.value;
      }
      if (datos.parametro == 'maxMgl') {

        this.maxMgl.value = datos.value;
      }
      this.modalstate = false
    },
    generarSerieMinutos(horaInicial, cantidadMinutos) {
      const serie = [];
      const [horas, minutos, segundos] = horaInicial.split(':').map(Number);
      let fecha = new Date();
      fecha.setHours(horas, minutos, segundos);

      for (let i = 0; i < cantidadMinutos; i++) {
        const tiempo = fecha.toTimeString().slice(0, 5); // Obtener "HH:MM:SS"
        serie.push(tiempo);
        fecha.setMinutes(fecha.getMinutes() + 1); // Incrementar 1 minuto
      }

      return serie;
    },

    async getdataendpoint() {

      //------------------------------- borrar


      let objFecha = new Date();
      const y = objFecha.getFullYear();
      const m = String(objFecha.getMonth() + 1).padStart(2, '0'); // Los meses   son 0-11
      const d = String(objFecha.getDate()).padStart(2, '0');

      const h = String(objFecha.getHours()).padStart(2, '0');
      const min = String(objFecha.getMinutes()).padStart(2, '0');
      const s = String(objFecha.getSeconds()).padStart(2, '0');
      let fechaHoraStr = `${y}-${m}-${d} ${h}:${min}:${s}`;

      if (this.injecta_fecha) {
        fechaHoraStr = `${this.injecta_fecha} ${h}:${min}:${s}`;
      }
      const [fecha, hora] = fechaHoraStr.split(' ');
      const [year, month, day] = fecha.split('-').map(Number);
      const [hours, minutes, seconds] = hora.split(':').map(Number);
      let objFechaHora = new Date(year, month - 1, day, hours, minutes, seconds);

      var nuevoFormato = {
        year: objFechaHora.getFullYear(),
        month: ('0' + (objFechaHora.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHora.getDate()).slice(-2),
        hours: ('0' + objFechaHora.getHours()).slice(-2),
        minutes: ('0' + objFechaHora.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHora.getSeconds()).slice(-2)
      };



      this.fFinal = `${nuevoFormato.year}-${nuevoFormato.month}-${nuevoFormato.day} ${nuevoFormato.hours}:${nuevoFormato.minutes}:${nuevoFormato.seconds}`;




      let resta_ms = 0



      if (this.granularity == '') {

        // 24 minutos ->  1,440 segundos -> 1440000
        resta_ms = 1440000
      }
      if (this.granularity == 'HOUR') {
        resta_ms = 43200000
      }
      if (this.granularity == 'DAY') {
        resta_ms = 1036800000
      }


      const [horas, minutos, segundos] = `${nuevoFormato.hours}:${nuevoFormato.minutes}:${nuevoFormato.seconds}`.split(':').map(Number);
      const f = new Date();
      f.setHours(horas, minutos, segundos, 0); // Setear hora, minutos, segundos y milisegundos a 0
      // Restar 31,400 milisegundos
      objFechaHora.setMilliseconds(f.getMilliseconds() - resta_ms);




      nuevoFormato = {
        year: objFechaHora.getFullYear(),
        month: ('0' + (objFechaHora.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHora.getDate()).slice(-2),
        hours: ('0' + objFechaHora.getHours()).slice(-2),
        minutes: ('0' + objFechaHora.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHora.getSeconds()).slice(-2)
      };


      this.fInicial = `${nuevoFormato.year}-${nuevoFormato.month}-${nuevoFormato.day} ${nuevoFormato.hours}:${nuevoFormato.minutes}:${nuevoFormato.seconds}`;




      if (!this.idJaula) {
        return false;
      }
      if (!this.sensor) {
        return false;
      }

      const apiKey = this.$store.state.login.tokenLogin;

      const data = {
        idUnit: this.idJaula,
        tsi: `${this.fInicial}`,
        tsf: `${this.fFinal}`,
        granularity: `${this.granularity}`
      };


      const fetchData = async () => {
        try {
          const url = "/api/mon/getUnitData";
          const response = await axios.post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`

            }
          });

          this.glosaDummyO2Saturado = '';
          this.glosaDummyO2Disuelto = '';
          const datos = response.data.payload.sensors;
          if (Array.isArray(datos) && datos.length == 0) {
            return false;
          }

          this.valorMaxY0 = 0;
          this.valorMinY0 = 0;
          this.valorMaxY1 = 0;
          this.valorMinY1 = 0;

          let temperaturaValues = [];
          let oxigenoValues = [];


          this.idSensorAFiltrar1 = this.objJaula.sensores[0]; // viene de props

          datos.filter(sensor => sensor.id === this.idSensorAFiltrar1)
            .forEach(sensor => {
              let indice = 0;

              sensor.params.forEach(param => {
                let id = param.id;

                if (id == 1 && this.idSensorAFiltrar1 == id) {

                  this.glosaDummyTemperatura = param.injectionType == 'dummy' ? `Temperatura simulada` : '';

                }
                if (id == 4 && this.idSensorAFiltrar1 == id) {
                  let gas = param.gas;
                  this.glosaDummyOzono = param.injectionType == 'dummy' ? `${gas} disuelto simulado` : '';

                }
                if (id == 5) {
                  let gas = param.gas;
                  this.glosaDummyO2Disuelto = param.injectionType == 'dummy' ? `${gas} disuelto simulado` : '';

                }

                if (id == 6 && this.idSensorAFiltrar1 == id) {
                  let gas = param.gas;
                  this.glosaDummyO2Saturado = param.injectionType == 'dummy' ? `${gas} saturado simulado` : '';

                }

                let splicedData = [...param.values].splice(-24);
                splicedData.forEach(valor => {

                  // contiene:  {'value':valor.value,'ts':valor.ts}

                  switch (id) {
                    case 1:
                      temperaturaValues.push({ ...valor });
                      this.valorMaxY1 <= valor.value ? (this.valorMaxY1 = valor.value) : (this.valorMinY1 = valor.value);
                      this.ajusteY()
                      break;
                    case 5:
                      oxigenoValues.push({ ...valor });



                      if (indice == 0) {
                        this.valorMinY0 = valor.value;
                        this.valorMaxY0 = valor.value;
                      } else {
                        if (valor.value > this.valorMaxY0) {
                          this.valorMaxY0 = valor.value;

                        } else {
                          if (valor.value < this.valorMinY0) {
                            this.valorMinY0 = valor.value
                          }
                        }
                      }


                      indice++;

                      this.ajusteY()
                      break;
                    case 4: this.ultorp = valor.value.toFixed(2); break;
                    case 6: this.ultmaxmgl = valor.value.toFixed(2); break;

                  }



                  this.result_mediciones_haydatos = true;
                  
                   const exists = this.$store.state.objStatusJaulas.some(jaula => jaula.idJaula === this.idJaula);
                   if (!exists) {
                     this.$store.state.objStatusJaulas.push({ idJaula: this.idJaula, status: true });
                   }
                });
              });
            });


          // Procesar oxigenoValues en una promesa
          return new Promise((resolve, reject) => {
            try {
              this.chartOptions.xAxis.categories = []
              let mediciones_oxigeno = [];
              let zones_oxigeno = []
              let mediciones_temp = [];

              let categorias = [];
              this.fs2 = '';
              let ultimo_injection_tag;


              temperaturaValues.forEach((mobile) => {



                let med = mobile["value"];
                mediciones_temp.push(med);
                this.ulttemp = med.toFixed(2);
                //console.log(med)
                console.log(year)



              });


              oxigenoValues.forEach((mobile, index) => {

                // zonas contiene  la posicion del array series  

                if (index == 0) {

                  ultimo_injection_tag = mobile["injection"]

                } else {

                  if (mobile["injection"] !== ultimo_injection_tag) {

                    zones_oxigeno.push({
                      tag: mobile["injection"],
                      value: index,
                      color: ultimo_injection_tag == false && mobile["injection"] == true ? 'cyan' : '',
                    })

                  } else {
                    zones_oxigeno.push({
                      tag: mobile["injection"],
                      value: index,
                      color: ultimo_injection_tag == true ? 'cyan' : '',
                    })


                  }

                  ultimo_injection_tag = mobile["injection"];

                }





                let med = mobile["value"];
                mediciones_oxigeno.push(med);
                this.ultmgl = med.toFixed(2);


                categorias.push(mobile["ts"].substr(mobile["ts"].length - 8, mobile["ts"].length));
                this.fs2 = index + " " + mobile["ts"] + "  " + med + " " + mobile["injection"] + "\n" + this.fs2;

                let t = mobile["ts"].split(" ")[0].split("-");
                const fects = t[2] + "-" + t[1];

                switch (index) {
                  case 0:
                    this.fechaInicialParche = fects;
                    this.horaInicialParche = mobile["ts"].split(" ")[1];
                    break;
                  case 11:

                    this.fechaMitadParche = fects;
                    this.horaMitadParche = mobile["ts"].split(" ")[1];
                    break;
                  default:
                    this.fechaFinalParche = fects;
                    this.horaFinalParche = mobile["ts"].split(" ")[1];

                }


              });


              this.chartOptions.xAxis.categories = categorias;
              this.chartOptions.series[1].data = mediciones_temp;
              this.chartOptions.series[0].data = mediciones_oxigeno;
              this.chartOptions.series[0].zones = zones_oxigeno;

              resolve({
                temperaturaValues,
                oxigenoValues,

                categorias,
                ultfecha: this.ultfecha,
                firstdate: this.firstdate,
                firsthour: this.firsthour,
                middledate: this.middledate,
                middlehour: this.middlehour,
                lastdate: this.lastdate,
                lasthour: this.lasthour,
                ultmgl: this.ultmgl
              });
            } catch (error) {
              reject(error);
            }
          });
        } catch (error) {
          console.error(error);
        }
      };

      fetchData().then(() => {



        this.result_mediciones = true;

      }).catch(error => {
        console.error("Error processing data:", error);
      });






    },
    ampliagrafico() {

      this.$emit("ampliaGrafico", this.idJaula, this.label);
    },
    openmodal(tag, esdefault) {
      console.log(esdefault)
      this.datosSetPoint = {}

      if (tag == 'maxORP') {
        this.datosSetPoint.value = this.maxORP.value;
        this.datosSetPoint.descripcion = this.maxORP.descripcion;

      }
      if (tag == 'reqO2') {
        this.datosSetPoint.value = this.reqO2.value;
        this.datosSetPoint.descripcion = this.reqO2.descripcion;
        this.datosSetPoint.tipo = tag;
      }
      if (tag == 'maxMgl') {
        this.datosSetPoint.value = this.maxMgl.value;
        this.datosSetPoint.descripcion = this.maxMgl.descripcion;
        this.datosSetPoint.tipo = tag;
      }
      console.log('descripcionSetPoint', this.descripcionSetPoint)

      this.modalstate = true
    },
    getSetPoints() {

      let setpoints = this.objJaula.setpoints;

      if (Array.isArray(setpoints) && setpoints.length > 0) {
        this.filtraSetPoints(setpoints);
      }

      if (Object.keys(this.reqO2).length > 0) {
        this.reqO2_tipo = this.parametroSetPointsLogin[this.reqO2.idPar].tipo;
        this.reqO2_ume = this.parametroSetPointsLogin[this.reqO2.idPar].unMed;
      }
      if (Object.keys(this.maxMgl).length > 0) {
        this.maxMgl_tipo = this.parametroSetPointsLogin[this.maxMgl.idPar].tipo;
        this.maxMgl_ume = this.parametroSetPointsLogin[this.maxMgl.idPar].unMed;
      }
      if (Object.keys(this.maxORP).length > 0) {
        this.maxORP_tipo = this.parametroSetPointsLogin[this.maxORP.idPar].tipo;
        this.maxORP_ume = this.parametroSetPointsLogin[this.maxORP.idPar].unMed;
      }

    },
    getDefaultSetPoints() {

      let defaultsetpoints = this.$store.state.login.userLogin.info.default_setpoints;
      if (Array.isArray(defaultsetpoints) && defaultsetpoints.length > 0) {
        this.filtraDefaultSetPoints(defaultsetpoints);
      }

      if (Object.keys(this.reqO2).length > 0) {

        this.reqO2_ume = this.reqO2.unMedida;
        this.reqO2_esDefault = this.reqO2.esDefault;
        this.reqO2.value = this.reqO2.valor;
      }
      if (Object.keys(this.maxMgl).length > 0) {

        this.maxMgl_ume = this.maxMgl.unMedida;
        this.maxMgl_esDefault = this.maxMgl.esDefault;
        this.maxMgl.value = this.maxMgl.valor;
      }
      if (Object.keys(this.maxORP).length > 0) {

        this.maxORP_ume = this.maxORP.unMedida;
        this.maxORP_esDefault = this.maxORP.esDefault;
        this.maxORP.value = this.maxORP.valor;
      }

    },
    ajusteY() {


      let estimadoMin = this.valorMinY0 - 0.4;
      let nuevoValorMin = estimadoMin < 0 ? 0 : estimadoMin;
      let nuevoValorMax = this.valorMaxY0 + 0.4;
      // this.fs = nuevoValorMin +  " " + nuevoValorMax

      let estimadoMin1 = this.valorMinY1 - 8;
      let nuevoValorMin1 = estimadoMin1 < 0 ? 0 : estimadoMin1;
      let nuevoValorMax1 = this.valorMaxY1 + 0.1;


      console.log(nuevoValorMin, nuevoValorMax, nuevoValorMin1, nuevoValorMax1)

      this.chartOptions = {
        ...this.chartOptions,

        yAxis: [
          {
            ...this.chartOptions.yAxis[0],
            max: nuevoValorMax,
            min: nuevoValorMin,

          },
          {
            ...this.chartOptions.yAxis[1],
            max: nuevoValorMax1,
            min: nuevoValorMin1,

          },
        ],
      };



    },

    getSensores() {
      console.log('getSensores')
      //let arrDefaultSetpoints = this.$store.state.login.userLogin.info.default_setpoints;

      let parametros_sensores = this.$store.state.login.userLogin.info.parametro_sensores;

      let sensores = this.objJaula.sensores
      let parametros_filtrados = this.filtrarParametros(sensores, parametros_sensores);
      this.ume1 = 'none'
      this.ume2 = 'none'
      if (Array.isArray(parametros_filtrados) && parametros_filtrados.length > 0) {


        this.id0 = parametros_filtrados[0]?.id ?? 'none';
        this.id1 = parametros_filtrados[1]?.id ?? 'none';

        this.tipo0 = parametros_filtrados[0]?.tipo ?? 'none';
        this.tipo1 = parametros_filtrados[1]?.tipo ?? 'none';

        this.ume0 = parametros_filtrados[0]?.unidad ?? 'none';
        this.ume1 = parametros_filtrados[1]?.unidad ?? 'none';


        if (this.id0 != '') {
          this.sensor[1].id = this.id0;  // oxigeno disuelto

          // leyenda   para el grafico
          this.chartOptions.series[1].name = this.tipo0 + ' ' + this.ume0;
        }
        if (this.id1 != '') {
          this.sensor[0].id = this.id1;
          // para leyenda del grafico
          this.chartOptions.series[0].name = this.tipo1 + ' ' + this.ume1;
        }

      }
    },
    filtraSetPoints(setpoints) {
      console.log('setpoints', setpoints)
      setpoints.forEach(s => {
        if (this.tagMappingSetPointsLogin[s.idPar]) {
          this[this.tagMappingSetPointsLogin[s.idPar]] = { 'id': s.id, 'idPar': s.idPar, 'value': s.value };
        }
      })
    },
    filtraDefaultSetPoints(defaultsetpoints) {


      let setpoints = this.objJaula.setpoints;
      defaultsetpoints.forEach(s => {
        let obj = { 'id': s.id, 'idPar': s.idPar, 'descripcion': s.descripcion, 'tipo': s.tipo, 'unMedida': s.unMedida };
        // busca valor setpoint en objJaula.setpoints


        // si no lo encuentra, poner valor por defecto  s.valor
        let resp = this.filtraValorSetPoint(setpoints, s)
        switch (s.idPar) {
          case 4: this.maxORP = obj;
            this.maxORP.valor = resp[0]
            this.maxORP.esDefault = resp[1]
            break;
          case 5: this.maxMgl = obj
            this.maxMgl.valor = resp[0]
            this.maxMgl.esDefault = resp[1]
            break;
          case 6: this.reqO2 = obj;
            this.reqO2.valor = resp[0]
            this.reqO2.esDefault = resp[1]
            break;
        }

      })
    },
    filtraValorSetPoint(setpoints, s) {
      let filteredData = setpoints.filter(item => item.idPar === s.idPar);
      let valor = 0;
      let esDefault;
      if (filteredData && Array.isArray(filteredData)) {
        valor = filteredData[0] ? filteredData[0].value : s.valor;
        esDefault = filteredData[0] ? false : true;

      }


      return [valor, esDefault];
    },
    filtrarParametros(sensores, parametro_sensores) {
      let resultados = [];

      sensores.forEach(sensorId => {
        parametro_sensores.forEach(parametro => {
          if (sensorId === parametro.id) {
            resultados = resultados.concat(parametro.parametros);
          }
        });
      });

      return resultados;
    },

  },
  watch: {

    injecta_fecha: function (valor) {
      if (!this.chart) return; // 👈 Verificación de seguridad
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series[1].data = [0];
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[0].zones = [];


      console.log('injecta_fecha:', valor)
      this.result_mediciones = false;

    },
    injecta_hora: function (valor) {
      console.log('injecta_fecha:', valor)
      this.result_mediciones = false;
    },


  },
  created() { },
  mounted() {

    this.idJaula = this.objJaula.id,
      this.getdataendpoint();
    this.getSensores();
    // this.getSetPoints();
    this.getDefaultSetPoints();
    this.polldata();
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },

};
</script>