<template>
  <div>

  

    <div v-if="this.objJaulasOcultas.length > 0" class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-1">
      <div class="container mx-auto  p-3 bg-gray-200 mt-3 text-black">
        {{ msg_jaulas_ocultas }}
      </div>
    </div>


    <div v-if="dataLoaded" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-8">
      {{ error_load }}



      <div v-for="objJaula in objJaulas" :key="objJaula.id"
        class="bg-white w-100 overflow-hidden   shadow-md rounded-md mb-4 p-0">


        <div class="p-1">
          <div class="flex items-center">
            <div class="flex-shrink-0"></div>
            <div class="ml-0 flex-1 text-left">


              <dd class="mt-0 text-xs ">
         
                <GraficoUnidadHighChart v-if="this.$store.state.gotolive!==true" :startDate="startDate" 
                                        :endDate="endDate" 
                                        :objJaula="objJaula"></GraficoUnidadHighChart>
                                        
                <GraficoUnidadHighChartLive   v-if="this.$store.state.gotolive==true"  :startDate="startDate" 
                                        :endDate="endDate" 
                                        :objJaula="objJaula"></GraficoUnidadHighChartLive>

              </dd>

            </div>
          </div>


        </div>
      </div>

    </div>
    
  </div>
</template>
<script>
 
import { mapGetters } from "vuex";



import GraficoUnidadHighChart from "./GraficoUnidadHighChart";
import GraficoUnidadHighChartLive from "./GraficoUnidadHighChartLive";
export default {
  name: "FaenasCliente",
  props: ["idEmpresa", "propIdFaena", "numRan", "propObjJaulas", "storeJaulas","propRangoFechas"],
  emits: ["chgFaena"],
  components: {
    
    GraficoUnidadHighChart,
    GraficoUnidadHighChartLive
  },
  mounted() {

    /*  let objFecha = new Date();
 
     const y = objFecha.getFullYear();
     const m = String(objFecha.getMonth() + 1).padStart(2, '0'); // Los meses   son 0-11
     const d = String(objFecha.getDate()).padStart(2, '0');
  */
    this.fecha_paso = `2024-08-16`;

    this.idFaena = this.propIdFaena;
    this.dataLoaded = false
    this.getDivisionesApi();
    this.setpoint1 = 6.2;
    this.setpoint2 = 300;
    this.idEstado = 1;


    this.dataLoaded = true;
    // this.loadUnidades();
    this.objJaulas = this.filtraVisibles(this.propObjJaulas);
    console.log("objJaulas:::::", this.objJaulas)
    this.fechasSeleccionadas = this.calculaHoy30Dias()
  },
  data() {
    return {
      dataList: [],
      dataLoaded: false,
      datos_mensuales: [],
      trenes: [],
      //objJaulas: [],
      objJaulas: [],
      objJaulasOcultas: [],
      cards: [{}],
      error_load: '',
      seriesFaenas: [],
      dots: ["green", "yellow", "green", "red"],
      setpoint1: 0,
      setpoint2: 0,
      idEstado: 0,
      idFaena: this.propIdFaena,
      show: false,
      f: '',
      msg_jaulas_ocultas: '',
      fecha_paso: '',
      injecta_fecha: '',
      fechasSeleccionadas: [],
      startDate:'',
      endDate:''
    };
  },
  methods: {
    cambiarfecha() {
      this.injecta_fecha = this.fecha_paso;
    },
    filtraVisibles(f) {
      this.objJaulasOcultas = f.filter(item => item.visible === false);
      if (this.objJaulasOcultas.length > 0) {
        this.msg_jaulas_ocultas = "Jaulas ocultas: " + this.objJaulasOcultas.length
      }
      this.objJaulas = f.filter(item => item.visible === true);
    },
    /*  loadUnidades(){
       this.f = this.$store.state.faenas;
      
 
       let obj = this.f.faenas.filter(item => item.id === this.idFaena);
       if (Array.isArray(obj[0].unidades)) {
         //this.objJaulas = obj[0].unidades.filter(unidad => unidad.natural !== 'S');
 
         this.objJaulas =  obj[0].unidades.map( unidad=>{
             if (unidad.natural !== 'S'){
              
                  return unidad;
                
             }
         })
 
         this.dataLoaded = true;  
        } 
     }, */
    async getDivisionesApi() {
      //console.log("carga diviones", "id empresa");
      try {



        this.loadTree = true;
        this.loadMediciones = false;
        this.loadVistaplanta = false;







      } catch (err) {
        console.log(err);
      }
    },
    calculaHoy30Dias() {

      let hoy = new Date();
      let diaHoy = String(hoy.getDate()).padStart(2, '0');
      let mesHoy = String(hoy.getMonth() + 1).padStart(2, '0');
      let añoHoy = hoy.getFullYear();
      let fechaHoy = `${diaHoy} ${mesHoy} ${añoHoy}`;



      let hace30Dias = new Date();
      hace30Dias.setDate(hoy.getDate() - 30);
      let diaHace30 = String(hace30Dias.getDate()).padStart(2, '0');
      let mesHace30 = String(hace30Dias.getMonth() + 1).padStart(2, '0');
      let añoHace30 = hace30Dias.getFullYear();
      let fechaHace30Dias = `${diaHace30} ${mesHace30} ${añoHace30}`;

      return [fechaHace30Dias, fechaHoy]

    },
    getFecCalendar(f) {
     
      
        this.startDate = this.convertirFormatoFecha(f[0]); //2023-11-01
        this.endDate = this.convertirFormatoFecha(f[1]);
 
         
    },

    convertirFormatoFecha(fechaOriginal) {
      if (!fechaOriginal) return false;

      const partes = fechaOriginal.split(" ");
      if (partes.length === 3) {
        const dia = partes[0];
        const mes = partes[1];
        const año = partes[2];

        const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

        if (!isNaN(nuevaFecha)) {
          const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
          return fechaFormateada;
        }
      }

      return null;
    },

  },
  computed: {
    ...mapGetters(["user", "token"]),

  },
  watch: {
    propRangoFechas: function (obj) {
        this.startDate = obj.startDate;
        this.endDate = obj.endDate;
    },  
    propIdFaena: function (value) {
      //this.dataLoaded = false;  
      //this.idFaena = value;
      // this.loadUnidades();
      console.log(value)
    },

    propObjJaulas: function (value) {

      //this.loadUnidades();
      this.filtraVisibles(value);

      this.dataLoaded = true;
    },
    storeJaulas: function (value) {
      alert('detecta cambios')
      console.log(value)
    }
  }

};
</script>
